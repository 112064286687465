import type DateFilter from '../../data-table/data-table-filters/date-filter/DateFilter';
import type FilterType from '../../data-table/data-table-filters/FilterType';
import type QueryFilter from '../../data-table/data-table-filters/query-filter/QueryFilter';
import type { UserPermissions } from './permissions';

export type UsersFilters = {
  [FilterType.DATE]: DateFilter;
  [FilterType.QUERY]: QueryFilter;
};

export enum UserRole {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super-admin',
  USER = 'user',
}

export type UserCredentials = {
  password: string;
  passwordRepeat: string;
  username: string;
};

export type SelectableUserRole = Extract<UserRole, UserRole.ADMIN | UserRole.USER>;

export type LoadedUser = {
  permissions: UserPermissions;
  role: UserRole;
  username: string;
};

export type AccessibleUser = {
  id: string;
  role: UserRole;
  username: string;
};
