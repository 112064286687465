import { computed, reactive, toRefs } from 'vue';

import { debounce } from 'lodash-es';

import DefaultLogger from '../../../lib/debug/logger/DefaultLogger';
import useLoadingIndication from '../../../lib/vue/useLoadingIndication';

const WEIGHT_MAPPING: Record<number, string> = {
  100: 'Thin',
  200: 'ExtraLight',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold',
  800: 'ExtraBold',
  900: 'Black',
};

// Текущая переменная для работы с цветовой схемой
const COLOR_SCHEMES = ['default', 'legacy', 'creative'] as const;
const TABLE_COLOR_SCHEMES = ['default', 'clean', 'striped'] as const;

type ColorScheme = (typeof COLOR_SCHEMES)[number];
export type TableColorScheme = (typeof TABLE_COLOR_SCHEMES)[number];

const DEFAULT_COLOR_SCHEME: ColorScheme = 'default';
const DEFAULT_TABLE_COLOR_SCHEME: TableColorScheme = 'default';

const DEFAULT_FONT = 'Vela Sans';
const DEFAULT_FONT_SIZE_DELTA = 0;
const FONT_LOAD_TIMEOUT = 3000;
const DEBOUNCE_DELAY = 150;

export const FONT_CONFIG = {
  [DEFAULT_FONT]: {
    path: '/assets/fonts/VelaSans/VelaSans',
    preload: true,
    weights: [400, 500, 600, 700],
  },
  'Fira Code': { path: '/assets/fonts/Fira_Code/FiraCode-VariableFont_wght', weights: 'auto' },
  'IBM Plex Mono': {
    path: '/assets/fonts/IBM_Plex_Mono/IBMPlexMono',
    weights: [200, 300, 400, 500, 600, 700, 900],
  },
  Inter: { path: '/assets/fonts/Inter/Inter-VariableFont_opsz,wght', weights: 'auto' },
  'Liberation Mono': { path: '/assets/fonts/LiberationMono/LiberationMono-Regular', weights: 'auto' },
  Montserrat: { path: '/assets/fonts/Montserrat/Montserrat-VariableFont_wght', weights: 'auto' },
  'Open Sans': { path: '/assets/fonts/OpenSans/OpenSans-VariableFont_wdth,wght', weights: 'auto' },
  Roboto: { path: '/assets/fonts/Roboto/Roboto-VariableFont_wdth,wght', weights: 'auto' },
  'Source Code Pro': { path: '/assets/fonts/SourceCodePro/SourceCodePro-VariableFont_wght', weights: 'auto' },
} as const;

type FontName = keyof typeof FONT_CONFIG;

interface FontSettings {
  selectedFont: FontName;
  selectedFontSizeDelta: number;
}

function isFontName(value: string): value is FontName {
  return Object.prototype.hasOwnProperty.call(FONT_CONFIG, value);
}

const debounceAsync = <T extends (...args: any[]) => Promise<any>>(function_: T, delay: number) => {
  const debounced = debounce((resolve: (value: any) => void, reject: (reason?: any) => void, args: Parameters<T>) => {
    function_(...args)
      .then(resolve)
      .catch(reject);
  }, delay);

  return (...args: Parameters<T>): ReturnType<T> => {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<T>;
  };
};

const uiSettings = reactive<
  { selectedColorScheme: ColorScheme; selectedTableColorScheme: TableColorScheme } & FontSettings
>({
  selectedColorScheme: DEFAULT_COLOR_SCHEME,
  selectedFont: DEFAULT_FONT,
  selectedFontSizeDelta: DEFAULT_FONT_SIZE_DELTA,
  selectedTableColorScheme: DEFAULT_TABLE_COLOR_SCHEME,
});

function applyColorScheme(colorScheme: ColorScheme) {
  document.documentElement.classList.remove(...COLOR_SCHEMES);
  if (colorScheme !== DEFAULT_COLOR_SCHEME) {
    document.documentElement.classList.add(colorScheme);
  }

  // Сохраняем цветовую схему в localStorage
  localStorage.setItem('selectedColorScheme', colorScheme);
  DefaultLogger.writeMessage(`Color scheme set to: ${colorScheme}`);
}

function applyTableColorScheme(tableColorScheme: TableColorScheme) {
  document.documentElement.classList.remove(...TABLE_COLOR_SCHEMES);
  document.documentElement.classList.add(`table-${tableColorScheme}`);
  localStorage.setItem('selectedTableColorScheme', tableColorScheme);
  DefaultLogger.writeMessage(`Table color scheme set to: ${tableColorScheme}`);
}

const fontLoadPromises = new Map<FontName, Promise<boolean>>();
const isFontLoadingSupported = () => 'fonts' in document && typeof document.fonts.load === 'function';

const loadFont = async (fontName: FontName): Promise<boolean> => {
  if (fontLoadPromises.has(fontName)) return fontLoadPromises.get(fontName)!;

  const promise = (async () => {
    const fontConfig = FONT_CONFIG[fontName];
    if (!fontConfig.path.trim()) {
      DefaultLogger.writeError(`Font ${fontName} not configured`);
      return false;
    }

    try {
      if (fontConfig.weights === 'auto') {
        const font = new FontFace(fontName, `url(${fontConfig.path}.woff2) format('woff2')`, {
          style: 'normal',
          weight: '100 900',
        });

        await font.load();
        document.fonts.add(font);
        return true;
      }

      const loadPromises = fontConfig.weights.map(async (weight) => {
        const mappedWeight = WEIGHT_MAPPING[weight] ?? 'Regular';
        const font = new FontFace(fontName, `url(${fontConfig.path}-${mappedWeight}.woff2) format('woff2')`, {
          style: 'normal',
          weight: String(weight),
        });

        return font.load().then((loadedFont) => {
          document.fonts.add(loadedFont);
          return true;
        });
      });

      try {
        await Promise.race([
          Promise.all(loadPromises),
          new Promise((_, reject) => setTimeout(() => reject(new Error('Font load timeout')), FONT_LOAD_TIMEOUT)),
        ]);
        return true;
      } catch (error) {
        if (error instanceof Error && error.message === 'Font load timeout') {
          DefaultLogger.writeError('Font loading timed out');
        }
        throw error;
      }
    } catch (error) {
      fontLoadPromises.delete(fontName);
      DefaultLogger.writeError('Font loading failed:', error);
      return false;
    }
  })();

  fontLoadPromises.set(fontName, promise);
  return promise;
};

export const useUiSettings = () => {
  const applyFontSettings = debounceAsync(async (settings: FontSettings) => {
    try {
      await loadFont(settings.selectedFont);
      document.documentElement.style.setProperty('--primary-font-family', `'${settings.selectedFont}'`);
      const fontSizeDelta = Math.max(-10, Math.min(10, settings.selectedFontSizeDelta));
      document.documentElement.style.setProperty('--font-size-delta', `${fontSizeDelta}px`);
    } catch (error) {
      DefaultLogger.writeError('Error applying font settings:', error);
    }
  }, DEBOUNCE_DELAY);

  const { isLoading: isInitLoading, resultCallback: initCallback } = useLoadingIndication(async () => {
    if (isFontLoadingSupported() && FONT_CONFIG[DEFAULT_FONT]?.preload) {
      await loadFont(DEFAULT_FONT);
    }

    const storedFontRaw = localStorage.getItem('selectedFont');
    const storedFont = (
      storedFontRaw !== null && storedFontRaw !== undefined && storedFontRaw.trim() !== ''
        ? storedFontRaw.replaceAll('"', '')
        : DEFAULT_FONT
    ).trim();
    const storedDelta = localStorage.getItem('selectedFontSizeDelta');
    const parsedDelta =
      storedDelta !== null && storedDelta !== undefined && storedDelta.trim() !== ''
        ? Math.min(10, Math.max(-10, Number.parseInt(storedDelta, 10)))
        : 0;

    uiSettings.selectedFont = isFontName(storedFont) ? storedFont : DEFAULT_FONT;
    uiSettings.selectedFontSizeDelta = parsedDelta;

    const storedColorScheme = (localStorage.getItem('selectedColorScheme') as ColorScheme) ?? DEFAULT_COLOR_SCHEME;
    const storedTableColorScheme =
      (localStorage.getItem('selectedTableColorScheme') as TableColorScheme) ?? DEFAULT_TABLE_COLOR_SCHEME;

    uiSettings.selectedColorScheme = storedColorScheme;
    uiSettings.selectedTableColorScheme = storedTableColorScheme;

    DefaultLogger.writeMessage('Initial settings:', {
      applied: uiSettings.selectedFont,
      colorScheme: storedColorScheme,
      delta: parsedDelta,
      stored: storedFont,
    });

    await applyFontSettings(uiSettings);
    applyColorScheme(storedColorScheme);
    applyTableColorScheme(storedTableColorScheme);
  });

  const { isLoading: isTableColorSchemeUpdating, resultCallback: tableColorSchemeUpdateCallback } =
    useLoadingIndication(async (newScheme: TableColorScheme) => {
      if (!TABLE_COLOR_SCHEMES.includes(newScheme)) {
        DefaultLogger.writeError('Attempt to set invalid table color scheme:', newScheme);
        return;
      }

      uiSettings.selectedTableColorScheme = newScheme;
      applyTableColorScheme(newScheme);
    });

  const { isLoading: isFontUpdating, resultCallback: fontUpdateCallback } = useLoadingIndication(
    async (newFont: FontName) => {
      if (!isFontName(newFont)) {
        DefaultLogger.writeError('Attempt to set invalid font:', newFont);
        return;
      }

      localStorage.setItem('selectedFont', newFont);
      uiSettings.selectedFont = newFont;
      await applyFontSettings(uiSettings);
    },
  );

  // Функция для обновления цветовой схемы
  const { isLoading: isColorSchemeUpdating, resultCallback: colorSchemeUpdateCallback } = useLoadingIndication(
    async (newScheme: ColorScheme) => {
      if (!COLOR_SCHEMES.includes(newScheme)) {
        DefaultLogger.writeError('Attempt to set invalid color scheme:', newScheme);
        return;
      }

      uiSettings.selectedColorScheme = newScheme;
      applyColorScheme(newScheme);
    },
  );

  const { isLoading: isSizeUpdating, resultCallback: sizeUpdateCallback } = useLoadingIndication(
    async (delta: number) => {
      const clampedDelta = Math.max(-10, Math.min(10, delta));
      localStorage.setItem('selectedFontSizeDelta', clampedDelta.toString());
      uiSettings.selectedFontSizeDelta = clampedDelta;
      await applyFontSettings(uiSettings);
    },
  );

  const { isLoading: isResetting, resultCallback: resetUiSettings } = useLoadingIndication(async () => {
    // Reset reactive state
    uiSettings.selectedColorScheme = DEFAULT_COLOR_SCHEME;
    uiSettings.selectedTableColorScheme = DEFAULT_TABLE_COLOR_SCHEME;
    uiSettings.selectedFont = DEFAULT_FONT;
    uiSettings.selectedFontSizeDelta = DEFAULT_FONT_SIZE_DELTA;

    // Reset localStorage values
    localStorage.setItem('selectedColorScheme', DEFAULT_COLOR_SCHEME);
    localStorage.setItem('selectedTableColorScheme', DEFAULT_TABLE_COLOR_SCHEME);
    localStorage.setItem('selectedFont', DEFAULT_FONT);
    localStorage.setItem('selectedFontSizeDelta', DEFAULT_FONT_SIZE_DELTA.toString());

    // Apply default settings
    applyColorScheme(DEFAULT_COLOR_SCHEME);
    applyTableColorScheme(DEFAULT_TABLE_COLOR_SCHEME);
    await applyFontSettings(uiSettings);
  });

  const isUiSettingsLoading = computed(
    () =>
      isInitLoading.value ||
      isFontUpdating.value ||
      isSizeUpdating.value ||
      isColorSchemeUpdating.value ||
      isResetting.value ||
      isTableColorSchemeUpdating.value,
  );

  const isDefaultSettings = computed(() => {
    return (
      uiSettings.selectedColorScheme === DEFAULT_COLOR_SCHEME &&
      uiSettings.selectedTableColorScheme === DEFAULT_TABLE_COLOR_SCHEME &&
      uiSettings.selectedFont === DEFAULT_FONT &&
      uiSettings.selectedFontSizeDelta === DEFAULT_FONT_SIZE_DELTA
    );
  });

  return {
    isUiSettingsLoading,
    ...toRefs(uiSettings),
    initUiSettings: initCallback,
    isDefaultSettings,
    resetUiSettings,
    updateColorScheme: colorSchemeUpdateCallback,
    updateFont: fontUpdateCallback,
    updateFontSizeDelta: sizeUpdateCallback,
    updateTableColorScheme: tableColorSchemeUpdateCallback,
  };
};
