import { defineStore } from 'pinia';

import useLocalStorageSync from '../../lib/local-storage/useLocalStorageSync';
import getSystemColorTheme from './getSystemColorTheme';
import ColorTheme from './models/ColorTheme';

type State = {
  colorTheme: ColorTheme;
};

const COLOR_THEME_LOCAL_STORAGE_ITEM_KEY = 'user-theme';

const colorThemeLocalStorage = useLocalStorageSync<ColorTheme>({
  getDefaultValue: () => getSystemColorTheme(),
  key: COLOR_THEME_LOCAL_STORAGE_ITEM_KEY,
});

const readColorThemeFromLocalStorageWithFallback = () => {
  try {
    return colorThemeLocalStorage.readValueFromLocalStorage();
  } catch {
    return (localStorage.getItem(COLOR_THEME_LOCAL_STORAGE_ITEM_KEY) as ColorTheme | null) ?? getSystemColorTheme();
  }
};

export default defineStore('colorTheme', {
  actions: {
    applyColorTheme() {
      document.documentElement.classList.remove('light', 'dark');
      document.documentElement.classList.add(this.colorTheme);
    },
    initializeColorTheme() {
      this.updateColorThemeWithoutSaving(readColorThemeFromLocalStorageWithFallback());
    },
    saveColorTheme() {
      const { colorTheme } = this;

      colorThemeLocalStorage.writeValueToLocalStorage(colorTheme);
    },
    toggleColorTheme() {
      if (this.colorTheme === ColorTheme.LIGHT) {
        this.updateColorTheme(ColorTheme.DARK);
      } else {
        this.updateColorTheme(ColorTheme.LIGHT);
      }
    },
    updateColorTheme(colorTheme: ColorTheme) {
      this.updateColorThemeWithoutSaving(colorTheme);

      this.saveColorTheme();
    },
    updateColorThemeWithoutSaving(colorTheme: ColorTheme) {
      this.colorTheme = colorTheme;

      this.applyColorTheme();
    },
  },
  state: (): State => ({
    colorTheme: ColorTheme.LIGHT,
  }),
});
