import { ref } from 'vue';

export default function useLoadingIndication<T extends unknown[], U>(
  callback: (...passedArguments: [...T]) => Promise<U> | U,
  {
    initial = false,
  }: {
    initial?: boolean;
  } = {},
) {
  const isLoading = ref(initial);

  const isFirstTime = ref(true);

  const resultCallback = async (...passedArguments: [...T]): Promise<U | null> => {
    if (isLoading.value && !isFirstTime.value) {
      return null;
    }
    isFirstTime.value = true;
    isLoading.value = true;
    const result = await callback(...passedArguments);
    isLoading.value = false;
    return result;
  };

  return {
    isLoading,
    resultCallback,
  };
}
