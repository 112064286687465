import ApiResultStatus from '../../shared/api/api-result/ApiResultStatus';
import useCurrentUserRole from '../authentication/current-user/useCurrentUserRole';
import { UserRole } from '../users/models/users';

export function useWithRoleAccessCheck() {
  const currentUserRole = useCurrentUserRole();

  return async <T extends (...args: any[]) => Promise<unknown>>(
    apiFunction: T,
    ...args: Parameters<T>
  ): Promise<Awaited<ReturnType<T>>> => {
    if (currentUserRole.value === UserRole.USER) {
      return {
        errorMessage: 'Permission denied',
        status: ApiResultStatus.ERROR,
      } as Awaited<ReturnType<T>>;
    }
    return (await apiFunction(...args)) as Awaited<ReturnType<T>>;
  };
}
